
body {
  margin: 0 !important;
  padding: 0 !important;
  background-color: #030325 !important;
}
.bg-primary {
  background-color: rgb(139, 14, 228) !important;
}
.bg-secondary {
  background-color: #030325 !important;
}
.btn-primary {
  background-color: rgb(139, 14, 228) !important;
  border: none !important;
}
.text-primary {
  color: rgb(139, 14, 228) !important;
}
/* .text-secondary
{
  color: rgb(139, 14, 228) !important;
} */
button.dropdown-toggle.btn.btn-secondary::after {
  content: none !important;
}
button.dropdown-toggle.btn.btn-secondary {
  background-color: #030325 !important;
}
.dropdown-actions-button,
.dropstart,
#react-aria972617230-10 {
  background-color: transparent !important;
  border: none !important;
}
.border-primary {
  border: 2px solid rgb(139, 14, 228) !important;
}
.btn-secondary {
  background-color: #28293d !important;
}
.bg-secondary2 {
  background-color: #28293d !important;
}
.row {
  width: 100%;
}
.nav-link {
  display: flex !important;
}
.nav-link.active {
  background-color: rgb(139, 14, 228) !important;
}
.header-icon {
  margin: 2px;
  font-size: 30px;
  align-items: center;
}
.icon {
  margin: 2px;
  font-size: 20px;
}
.nav-link .icon {
  /* margin: 2px !important; */
  margin-inline-end: 10px;
  font-size: 20px;
}
.main-sidebar,
.main-header {
  background-color: #030325 !important;
  border: none !important;
}
.wrapper {
  background-color: #030325 !important;
}
.content-wrapper {
  border-radius: 10px;
  border: rgba(139, 14, 228, 0.105) solid !important;
  background-color: #28293d !important;
}
.content {
  color: white !important;
}
.search-item {
  display: flex;
  background-color: #28293d !important;
  border-radius: 5px;
  align-items: center;
}
.search-item .icon {
  font-size: 25px !important;
}
.search-input,
.search-input:focus {
  background-color: transparent;
  color: white !important;
  border: none;
}
.nav-tabs {
  border: 1px solid white;
  /* border-bottom: 2px solid white; */
  border-radius: 5px;
}
.nav-tabs .nav-item button.nav-link {
  background-color: transparent !important;
  color: white;
  border: none !important;

  border-radius: 5px;
}
.nav-tabs .nav-item button.nav-link.active {
  background-color: rgb(139, 14, 228) !important;
  border-bottom: 1px solid white !important;
}
span.page-link {
  border-color: transparent !important;
}
.page-link {
  background-color: rgb(139, 14, 228) !important;
  color: white !important;
  border-color: transparent;
}
@media screen and (min-width: 768px) {
  .search-item {
    width: 400px;
  }
}
.card-img-top {
  width: auto !important;
  height: 100px !important;
}
div.card-title {
  float: none !important;
  width: auto !important;
}
div.bg-secondary2.p-3.dropdown-menu.show {
  width: 200px !important;
}
.avatar {
  border-radius: 50px;
  width: 50%;
  height: 50%;
}
.form-control {
  background-color: #030325 !important;
  color: white !important;
}
.form-control2 {
  background-color: #28293d !important;
  color: white !important;
}
.radio {
  border: 2px solid white;
  box-shadow: 0 0 0 1px rgb(139, 14, 228);
  appearance: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: #fff;
  transition: all ease-in 0.2s;
}
.radio:checked {
  background-color: rgb(139, 14, 228);
}
.striped {
  background-color: #f5f5f5 !;
}

td {
  max-width: 200px !important;
}
.subject_item {
  background-color: rgb(139, 14, 228);
  margin: 15px;
  padding-inline: 15px;
  border-radius: 50px;
}
.language_item {
  background-color: rgb(139, 14, 228);
  /* margin: 15px; */
  padding-inline: 15px;
  border-radius: 50px;
}
.radio-toolbar {
  background-color: #030325;
  align-items: center;
  border-radius: 10px !important;
  border: 1px solid white;
  width: fit-content !important;
  height: fit-content !important;
}
.radio-toolbar input[type="radio"] {
  display: none;
}

.radio-toolbar label {
  margin-bottom: 0;
  border-radius: 10px !important;
  height: calc(2.25rem + 2px);
  align-items: center;
  align-content: center;
  display: inline-block;
  background-color: #030325;
  padding: 4px 11px;
  font-family: Arial;
  font-size: 16px;
  cursor: pointer;
  font-weight: 200 !important;
}

.radio-toolbar input[type="radio"]:checked + label {
  background-color: rgb(139, 14, 228);
}
.iphone {
  margin: auto;
  border: 10px solid black;
  border-radius: 40px;
  /* width: 300px; */
  height: 600px;
  position: relative;
  box-shadow: 0px 20px 15px #33333335;
  overflow: hidden; /* Remove the scroll from the container */
}

.iphone::-webkit-scrollbar {
  display: none;
}

.screen {
  padding-top: 20px;
  z-index: 10;
  position: relative;
  background-size: cover;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  overflow: hidden; /* Remove the scroll from the container */
}

.screen-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 55px; /* Adjust the value if needed */
  overflow: auto;
  padding: 10px;
  box-sizing: border-box;
}

.fix {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 55px; /* Adjust the value if needed */
  padding: 10px;
  box-sizing: border-box;
  background-color: #f8f9fa;
}

.fix .form-control {
  margin-bottom: 10px;
  border-radius: 30px;
  background-color: white !important;
  color: black !important;
}
.display-5 {
  font-size: 25px;
}
.border-radius-3 {
  border-radius: 10px;
}
.blue-shadow {
  color: rgb(0, 115, 255);
  box-shadow: 0 0 5px 10px rgba(0, 115, 255, 0.4);
}
.yellow-shadow {
  color: rgb(255, 230, 0);
  box-shadow: 0 0 5px 10px rgba(255, 230, 0, 0.4);
}
.purple-shadow {
  color: rgb(139, 14, 228);
  box-shadow: 0 0 5px 10px rgba(139, 14, 228, 0.4);
}
.orange-shadow {
  color: rgb(248, 163, 4);
  box-shadow: 0 0 5px 10px rgba(248, 163, 4, 0.4);
}
.green-shadow {
  color: rgb(1, 141, 1);
  box-shadow: 0 0 5px 10px rgba(1, 141, 1, 0.4);
}
.red-shadow {
  color: rgb(237, 30, 71);
  box-shadow: 0 0 5px 10px rgba(237, 30, 71, 0.4);
}
.table-striped thead tr:nth-of-type(odd) {
  background-color: #433c5f !important;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #1e1e2e !important;
}
.table-striped tbody tr:nth-of-type(even) {
  background-color: #38384a !important;
}
@media screen and (min-width: 768px) {
  .pie-chart {
    width: calc(100%);
    height: calc(100%);
  }
}
div.sidebar.mt-3::-webkit-scrollbar {
  display: none;
}
div.ten:before {
  content: "";
  position: absolute;
  border: 10px dashed grey;
  top: -8px;
  bottom: -8px;
  left: -8px;
  right: -8px;
}
.container1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
div.table-responsive {
  background-color: #28293d !important;
}
.image-chooser {
  height: 200px;
  width: 200px;
  border-radius: 10px;
  background-color: rgba(139, 14, 228, 0.3);
  border: 2px rgb(139, 14, 228) dashed;
}
.icon-chooser
{
  color: rgb(139, 14, 228);
  font-size: 80px;
}
.custom-file-button {
  display: inline-block;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 6px 12px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
}

.custom-file-button input {
  display: none;
}
.card-body
{
  padding: 10px !important;
}
li.page-item a.page-link
{
  background-color: transparent !important;
  border: 1px solid rgb(139, 14, 228);
}
li.page-item.disabled span
{
  background-color: transparent !important;
}
.visually-hidden
{
  display: none;
}
table
{
  overflow: scroll !important;
}
.icon2
{
  font-size: 30px;
}
@media screen and (min-width:992px) and (max-width:1535px) {
  div.dashboard-counters.col-lg-2.col-md-4
  {
    /* flex: clac(33.333333%/1.5); */
    max-width: 16.666667%;
  }
}
.login
{
  background-color: #000836 !important;
}
@media screen and (min-width:640px) {
  .login{
    display: flex;
    justify-items: center;
    align-items: center;
  }
  .login img
  {
    width: 50%;
    height: 50%;
  }
}
@media screen and (max-width:639px) {
.login-page
{
  height: auto;
}
.login img
  {
    width: 100%;
  }
}
.login-page
{
  background-color: transparent !important;
}
.login-card-body, .login-box .card
{
  background-color: transparent !important;
}

.rotate-0
{
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}
.rotate-90
{
  transform: rotate(90deg);
  transition: transform 0.3s ease;
}
.menu-show
{
  display: block;
  transition: all 0.3s ease;
}
.menu-hide
{
  display: none;
  transition: all 0.3s ease;
}
input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        /* margin: 0; */
      }
      .custom-audio-player {
        /* Add your custom styles here */
        width: 300px; /* Set the desired width */
        height: 50px; /* Set the desired height */
        /* Add any other styles you want to customize */
      }
audio.react-audio-player
{
  width: 100%;
}
      audio::-webkit-media-controls-enclosure {
        background-color: #c6c6ec;
        width: 100%;
    }
    audio::-webkit-media-controls-timeline {}
    audio::-webkit-media-controls-volume-control-container {}
    audio::-webkit-media-controls-volume-control-container.closed {}
    audio::-webkit-media-controls-volume-slider-container {}
    audio::-webkit-media-controls-volume-slider {}
    audio::-webkit-media-controls-seek-back-button {}
    audio::-webkit-media-controls-seek-forward-button {}
    audio::-webkit-media-controls-fullscreen-button {}
    audio::-webkit-media-controls-rewind-button {}
    audio::-webkit-media-controls-return-to-realtime-button {}
    audio::-webkit-media-controls-toggle-closed-captions-button {}
